
import { PageResponse } from "~/dto/@common";
import { ListNftFilterReq } from "~/dto/nft-collection.dto";
import { NftCollection } from "~/dto/nft-project.dto";
import { freeMintApiService, } from "./@global";

const ENPOINT = {
  listFreeMint: "api/nft-collections/list-free-mint".trim(),
}

export class NftCollectionService {

  async listFreeMint(params: ListNftFilterReq) {
    return freeMintApiService.get<PageResponse<NftCollection>>(ENPOINT.listFreeMint, params);
  }

}

export default new NftCollectionService();
