import { Box, HStack, Text, useColorMode, VStack } from "@chakra-ui/react";
import { useMemo } from "react";
import { useQueryParams, withDefault, StringParam } from "use-query-params";
import { codeHelpers } from "~/@helpers/code.helpers";

export const NavLinkCustom = (props: {
  mainLink: any;
  onClose?: () => void;
  isMobile: boolean;
}) => {
  const { mainLink, onClose = () => {}, isMobile } = props;
  const [{ keyword }, setKeyword] = useQueryParams({
    keyword: withDefault(StringParam, ""),
  });

  const isFocused = useMemo(() => {
    if (keyword) {
      return mainLink.key === keyword;
    }
    return false;
  }, [keyword, mainLink.key]);

  return (
    <VStack spacing={6} alignItems={{ base: "start", lg: "center" }}>
      <HStack
        mt={{ base: "10px" }}
        spacing={6}
        onClick={() => {
          onClose();
          if (mainLink.href) {
            window.open(mainLink.href);
          } else {
            codeHelpers.scrollToTop(mainLink.key);
            setKeyword({ keyword: mainLink.key });
          }
        }}
        cursor="pointer"
      >
        <Text
          fontSize={{ base: "16px", lg: "18px" }}
          lineHeight={{ base: "26px", lg: "23px" }}
          letterSpacing="0.01em"
          fontFamily={isMobile ? "Jost-Medium" : "inherit"}
          fontWeight={isFocused ? 700 : isMobile ? 600 : 400}
          color={"#fff"}
          _hover={{
            fontWeight: 700,
          }}
          _focus={{
            fontWeight: 700,
          }}
          _active={{
            fontWeight: 700,
          }}
          textTransform="capitalize"
        >
          {mainLink.title}
        </Text>
      </HStack>

      <Box
        mt="5px !important"
        display={{ base: "none", lg: "block" }}
        height={"2px"}
        width={"16px"}
        background={isFocused ? `#fff` : "tranparent"}
        borderRadius="100px"
      />
    </VStack>
  );
};
