import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { ChakraProvider, } from '@chakra-ui/react'
import { Suspense, useMemo, } from 'react'
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { withStore } from 'react-context-hook'
import BigNumber from 'bignumber.js'
import { QueryParamProvider } from 'use-query-params'
import { Web3ReactProvider } from '@web3-react/core'

import { ToastContainer, } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { getLibrary } from './@helpers/web3-react.helper'
import { RefreshContextProvider } from './contexts/RefreshContext'
import ModalProvider from './contexts/ModalContext'
import { WalletProvider } from './contexts/WalletContext'
import customTheme from './styles/Themes'
import NotFoundView from './views/404'
import ComingSoon from './components/ComingSoon'
import { Routers } from './@constants/Routers'
import TagManager from 'react-gtm-module'
import { I18nextProvider } from 'react-i18next'
import i18n from './@helpers/i18n'

import HomeViewV2 from './views/HomeView'

// export const TRACKING_ID_GTM = 'GTM-K7CXTRT'

// const tagManagerArgs = {
//   gtmId: TRACKING_ID_GTM
// }

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

// TagManager.initialize(tagManagerArgs)

const RouteAdapter = ({ children }: { children: any }) => {
  const navigate = useNavigate()
  const location = useLocation()


  const adaptedHistory = useMemo(
    () => ({
      replace(location: any) {
        navigate(location, { replace: true, state: location.state })
      },
      push(location: any) {
        navigate(location, { replace: false, state: location.state })
      },
    }),
    [navigate],
  )
  return children({ history: adaptedHistory, location })
}

function App() {
  return (
    <ChakraProvider theme={customTheme}>

      <I18nextProvider i18n={i18n}>
        <Suspense fallback="">

          <QueryParamProvider ReactRouterRoute={RouteAdapter as any}>
            <RefreshContextProvider>

              <WalletProvider>
                <ModalProvider>

                  <Routes>

                    <Route caseSensitive path="/" element={<HomeViewV2 />} />

                    <Route path="/*" element={<NotFoundView />} />

                  </Routes>

                </ModalProvider>
              </WalletProvider>

            </RefreshContextProvider>
          </QueryParamProvider>

        </Suspense>
      </I18nextProvider>

    </ChakraProvider>
  )
}

const ContainerApp = () => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <BrowserRouter>
        <App />
        <ToastContainer />
      </BrowserRouter>
    </Web3ReactProvider>
  )
}

const initialState = {}

const storeConfig = {
  listener: (state, key, prevValue, nextValue) => {
  },
  logging: process.env.NODE_ENV !== 'production',
}

export default withStore(ContainerApp, initialState, storeConfig)
