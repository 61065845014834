import { Routers } from "~/@constants/Routers";

const stringDateToUTCDate = (dateStr: string) => {
    const inputDate = new Date(dateStr);
    return new Date(inputDate.getTime() - inputDate.getTimezoneOffset() * 60000);
}

const checkIsFocusUrl = (pathname: string, key: string) => {
    const url = pathname.split("/");

    if (pathname === "/" && key === 'home') {
        return true;
    }
    if (key === Routers.BUILD && (url.includes(Routers.ACADEMY) || url.includes(Routers.SHOWCASE))) {
        return true;
    }
    if (url.includes(key)) {
        return true;
    }
    return false;
}

const scrollToTop = (elementId: string) => {
    const element = document.getElementById(elementId);
    if (element) {
        element.scrollIntoView({ behavior: "smooth", block: 'center' })
    }
}


export const codeHelpers = {
    stringDateToUTCDate,
    checkIsFocusUrl,
    scrollToTop,
}