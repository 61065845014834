import { useRef, useState } from "react";
import {
  Box,
  VStack,
  Text,
  GridItem,
  Grid,
  HStack,
} from "@chakra-ui/react";
import { useWindowSize } from "~/hooks/@global";
//@ts-ignore
import TokenNFtFeedMp4 from "~/assets/home/TOken_NFTFeed.mp4";
//@ts-ignore
import NFTDropMp4 from "~/assets/home/NFTDrop.mp4";
//@ts-ignore
import GuaranteedMp4 from "~/assets/home/Guaranteed_INO.mp4";
import PrimaryButton from "~/components/PrimaryButton";

const DATA = [
  {
    title: "NFT Valuation",
    subTitle:
      "Through the use of Chainlink's price data, NFT Valuation is able to update the value of tokens every three blocks, ensuring that NFT owners always have an accurate understanding of their digital assets worth",
  },
  {
    title: "NFT Feeding",
    subTitle:
      "NFT Feeding offers a unique way to grow your NFT's value by allowing you to merge your digital assets with your NFT. This means that your NFT now holds the power of your digital assets, giving you full control over both your NFT and the assets inside.",
  },
  {
    title: "Aggregator Farming",
    subTitle:
      "Aggregator Farming allows the NFT to participate in a range of lending, borrowing, and farming activities, optimizing the use of assets held within the NFT and potentially increasing its market value.",
  },
  {
    title: "Redemption",
    subTitle:
      "Redemption is a feature that enables users to claim ownership of NFT (random NFT or target NFT) in a Vault by utilizing xToken. However, if there are no more NFTs left in the Vault, users can only mint random NFTs and cannot use the target minting NFT feature.",
  },
  {
    title: "NFT Future Contract",
    subTitle:
      "NFT Future Contract allows users to lock in their future assets into an NFT and set a future price, offering a unique way to sell and trade digital assets.",
  },
];

export const NFTFeeding = () => {
  const [selectItem, setSelectItem] = useState(0);
  const { width } = useWindowSize();
  const videoRef = useRef(null);

  const renderContent = () => {
    return (
      <Grid w="full" templateColumns="repeat(7, 1fr)" gap={8}>
        <GridItem
          colSpan={{ base: 7, xl: 3 }}
          border="0.5px solid #F95F0B"
          borderRadius={{ base: "24px", lg: "24px" }}
          ml='20px'
        >
          <VStack spacing={0}>
            {DATA.map((item, idx) => {
              return (
                <VStack
                  key={idx}
                  w="full"
                  cursor="pointer"
                  alignItems="start"
                  spacing={2}
                  bg="rgba(29,29,35,.3)"
                  boxShadow="0 32px 64px rgba(0,0,0,.12)"
                  p={{ base: "28px", lg: "41px" }}
                  borderBottom="1px solid rgba(105,106,111,.5)"
                  onClick={() => {
                    setSelectItem(idx);
                  }}
                  onMouseOver={() => {
                    setSelectItem(idx);
                  }}
                  onMouseLeave={() => {
                    setSelectItem(0);
                  }}
                >
                  <HStack>
                    {selectItem === idx ? (
                      <Text
                        background={
                          "radial-gradient(50.59% 160.64% at 12.02% -26.12%, #F95F0B 0%, #E2103D 100%)"
                        }
                        backgroundClip="text"
                        fontSize={"26px"}
                        fontWeight={700}
                        fontFamily="Jost-Bold"
                        lineHeight={"38px"}
                        alignSelf="start"
                      >
                        ○ {item.title}
                      </Text>
                    ) : (
                      <Text
                        fontSize={"26px"}
                        fontWeight={700}
                        fontFamily="Jost-Bold"
                        lineHeight={"38px"}
                        alignSelf="start"
                        color={"#FFFFFF"}
                      >
                        {item.title}
                      </Text>
                    )}
                  </HStack>

                  {selectItem === idx && (
                    <Text
                      fontSize="16px"
                      lineHeight="30px"
                      color="#FFFFFF"
                      opacity={"0.8"}
                    >
                      {item.subTitle}
                    </Text>
                  )}
                </VStack>
              );
            })}
          </VStack>
        </GridItem>

        <GridItem colSpan={{ base: 7, xl: 4 }}>
          <video
            ref={videoRef}
            loop
            style={{
              height: "700px",
              width: "100%",
            }}
            //@ts-ignore
            autoPlay={true}
            //@ts-ignore
            playsInline="playsInline"
            //@ts-ignore
            muted="muted"
            src={TokenNFtFeedMp4}
          ></video>
        </GridItem>
      </Grid>
    );
  };

  const renderMbContent = () => {
    return (
      <Grid w="full" templateColumns="repeat(7, 1fr)" gap={8}>
        <GridItem colSpan={{ base: 7, xl: 4 }}>
          <video
            ref={videoRef}
            loop
            style={{
              height: "100%",
              width: "100%",
            }}
            //@ts-ignore
            autoPlay={true}
            //@ts-ignore
            playsInline="playsInline"
            //@ts-ignore
            muted="muted"
            src={TokenNFtFeedMp4}
          ></video>
        </GridItem>
        <GridItem
          colSpan={{ base: 7, xl: 3 }}
          border="0.5px solid #F95F0B"
          borderRadius={{ base: "24px", lg: "24px" }}
          mx={'20px'}
        >
          <VStack spacing={0}>
            {DATA.map((item, idx) => {
              return (
                <VStack
                  key={idx}
                  w="full"
                  cursor="pointer"
                  alignItems="start"
                  spacing={2}
                  bg="rgba(29,29,35,.3)"
                  boxShadow="0 32px 64px rgba(0,0,0,.12)"
                  p={{ base: "30px", lg: "44px" }}
                  borderBottom="1px solid rgba(105,106,111,.5)"
                  onClick={() => {
                    setSelectItem(idx);
                  }}
                  onMouseOver={() => {
                    setSelectItem(idx);
                  }}
                  onMouseLeave={() => {
                    setSelectItem(0);
                  }}
                >
                  <HStack>
                    {selectItem === idx ? (
                      <Text
                        background={
                          "radial-gradient(50.59% 160.64% at 12.02% -26.12%, #F95F0B 0%, #E2103D 100%)"
                        }
                        backgroundClip="text"
                        fontSize={{ base: '20px', lg: "26px" }}
                        fontWeight={700}
                        fontFamily="Jost-Bold"
                        lineHeight={{ base: '28px', lg: "38px" }}
                        alignSelf="start"
                      >
                        ○ {item.title}
                      </Text>
                    ) : (
                      <Text
                        fontSize={{ base: '20px', lg: "26px" }}
                        fontWeight={700}
                        fontFamily="Jost-Bold"
                        lineHeight={{ base: '28px', lg: "38px" }}
                        alignSelf="start"
                        color={"#FFFFFF"}
                      >
                        {item.title}
                      </Text>
                    )}
                  </HStack>

                  {selectItem === idx && (
                    <Text
                      fontSize={{ base: '14px', lg: "16px" }}
                      lineHeight="30px"
                      color="#FFFFFF"
                      opacity={"0.8"}
                    >
                      {item.subTitle}
                    </Text>
                  )}
                </VStack>
              );
            })}
          </VStack>
        </GridItem>
      </Grid>
    );
  };

  return (
    <Box
      w="100%"
      // background={"#0E0D22"}
      position={"relative"}
      paddingBottom={{ base: "45px", lg: "15px" }}
    >
      <VStack
        spacing={{ base: 2, md: 4 }}
        alignItems={{ base: "center" }}
        paddingTop={{ base: "35px", lg: "51px" }}
      //px={{ base: "16px", lg: "30px", xl: "120px" }}
      >
        <Text
          background={
            "radial-gradient(50.59% 160.64% at 12.02% -26.12%, #F95F0B 0%, #E2103D 100%)"
          }
          backgroundClip="text"
          fontFamily={"Jost-Medium"}
          fontWeight={500}
          fontSize={{ base: "32px", lg: "42px" }}
          lineHeight={{ base: "41px", lg: "61px" }}
          textAlign={{ base: "center" }}
          marginTop={0}
          pb={"24px"}
          id="what-is-curators-network"
          textShadow={"0px 0px 46px #FB0439"}
        >
          NFT Feeding
        </Text>
        <Text
          color={"#FFFFFF"}
          fontFamily={"Jost-Bold"}
          fontWeight={800}
          fontSize={{ base: "30px", lg: "38px" }}
          lineHeight={{ base: "33px", lg: "54px" }}
          textAlign={{ base: "center" }}
          marginTop={0}
          pb={"24px"}
          px={{ base: '20px', lg: '0px' }}
          id="what-is-curators-network"
        >
          Nurture your NFTs and watch their value grow
        </Text>
        <Text
          color={"#FFFFFF"}
          fontFamily={"Jost"}
          fontWeight={"400"}
          textAlign={"center"}
          pb={"30px"}
          lineHeight={{ base: '24px', lg: '27px' }}
          px={{ base: '20px', lg: '0px' }}
          fontSize={{ base: '14px', lg: '16px' }}
        >
          NFT Feeding helps "nurture" NFTs and feed them valuable resources,
          adding to their worth and desirability.
        </Text>
        {width < 992 ? renderMbContent() : renderContent()}
        <Text
          color={"#FFFFFF"}
          fontFamily={"Jost-Bold"}
          fontWeight={800}
          fontSize={{ base: "32px", lg: "38px" }}
          lineHeight={{ base: "41px", lg: "54px" }}
          textAlign={{ base: "center" }}
          marginTop={0}
          p={"30px"}
        >
          Our highlight services
        </Text>
        <HighlightService />
      </VStack>
    </Box>
  );
};

const HighlightService = () => {
  const { width } = useWindowSize();
  const videoRef2 = useRef(null);
  const videoRef3 = useRef(null);
  return (
    <>
      <Grid
        w="full"
        gap={6}
        alignItems="center"
        templateColumns="repeat(12, 1fr)"
      >
        <GridItem colSpan={{ base: 12, xl: 6 }}>
          <video
            ref={videoRef3}
            loop
            style={{
              height: width < 992 ? "306px" : "535px",
              width: "100%",
            }}
            //@ts-ignore
            autoPlay={true}
            //@ts-ignore
            playsInline="playsInline"
            //@ts-ignore
            muted="muted"
            src={NFTDropMp4}
          ></video>
        </GridItem>
        <GridItem colSpan={{ base: 12, xl: 6 }}>
          <VStack
            padding={{ base: "24px", lg: "32px" }}
            spacing={{ base: 4, lg: 1 }}
            alignItems={{ base: "center", lg: "start" }}
            fontFamily={"Jost"}
            fontWeight={700}
          >
            <VStack w="full">
              <Text
                background={
                  "radial-gradient(50.59% 160.64% at 12.02% -26.12%, #F95F0B 0%, #E2103D 100%)"
                }
                backgroundClip="text"
                fontSize={{ base: '20px', lg: "26px" }}
                fontWeight={700}
                fontFamily="Jost-Bold"
                lineHeight={{ base: '28px', lg: "38px" }}
                alignSelf={{ base: "center", lg: "start" }}
              >
                ○ Guaranteed INO
              </Text>
            </VStack>

            <Text
              fontWeight={400}
              color="#FFFFFF"
              fontFamily={"Jost"}
              fontSize={{ base: "14px", lg: "16px" }}
              lineHeight={{ base: "24px", lg: "28px" }}
              textAlign={{ base: "center", lg: "start" }}
              maxWidth={{ base: "full" }}
              letterSpacing="0.3px"
            >
              With Guaranteed INO, users can have a worry-free experience buying
              and selling NFTs and enjoy the benefits of added liquidity and a
              risk-free trial period. During the warranty period, users have the
              right to swap the NFT back into the liquidity pool and claim back
              their money (not yet include the return fee of 5%)
            </Text>
          </VStack>
        </GridItem>
      </Grid>
      {width > 992 ? (
        <Grid
          w="full"
          gap={6}
          alignItems="center"
          templateColumns="repeat(12, 1fr)"
        >
          <GridItem colSpan={{ base: 12, xl: 6 }}>
            <VStack
              padding={{ base: "24px", lg: "32px" }}
              spacing={{ base: 4, lg: 1 }}
              alignItems={{ base: "center", lg: "start" }}
              fontFamily={"Jost"}
              fontWeight={700}
            >
              <VStack w="full">
                <Text
                  background={
                    "radial-gradient(50.59% 160.64% at 12.02% -26.12%, #F95F0B 0%, #E2103D 100%)"
                  }
                  backgroundClip="text"
                  fontSize={{ base: '20px', lg: "26px" }}
                  fontWeight={700}
                  fontFamily="Jost-Bold"
                  lineHeight={{ base: '28px', lg: "38px" }}
                  alignSelf={{ base: "center", lg: "end" }}
                >
                  ○ NFT Drop
                </Text>
              </VStack>

              <Text
                fontWeight={400}
                color="#FFFFFF"
                fontFamily={"Jost"}
                fontSize={{ base: "14px", lg: "16px" }}
                lineHeight={{ base: "20px", lg: "28px" }}
                textAlign={{ base: "center", lg: "right" }}
                maxWidth={{ base: "full" }}
                letterSpacing="0.3px"
              >
                NFT Drops feature allows for the distribution of NFT to a select
                group of user as rewards for completing missions or for
                promotional purposes. Project NFT owners can set aside a
                specific number of NFTs to be used for airdrops, incentivizing
                user participation and spreading awareness of the project.
              </Text>

              <PrimaryButton
                alignSelf={"end"}
                w={{ base: "full", lg: "174px" }}
                h={{ base: '40px', lg: "58px" }}
                borderRadius="100px"
                borderColor={'transparent'}
                background="radial-gradient(50.59% 160.64% at 12.02% -26.12%, #F95F0B 0%, #E2103D 100%)"
                p={{ base: "16px 26px" }}
                onClick={() => {
                  window.open("https://mint.nftfeed.guru/", '_blank');
                }}
              >
                <Text
                  fontFamily={"Jost-Medium"}
                  fontSize={{ base: "16px", md: "18px" }}
                  lineHeight={{ base: "25px", md: "26px" }}
                  fontWeight={700}
                  letterSpacing={"-0.03em"}
                  color={"#FFFFFF"}
                >
                  Join Now
                </Text>
              </PrimaryButton>

            </VStack>
          </GridItem>
          <GridItem colSpan={{ base: 12, xl: 6 }}>
            <video
              ref={videoRef2}
              loop
              style={{
                height: width < 992 ? "306px" : "535px",
                width: "100%",
              }}
              //@ts-ignore
              autoPlay={true}
              //@ts-ignore
              playsInline="playsInline"
              //@ts-ignore
              muted="muted"
              src={GuaranteedMp4}
            ></video>
          </GridItem>
        </Grid>
      ) : (
        <Grid
          w="full"
          gap={6}
          alignItems="center"
          templateColumns="repeat(12, 1fr)"
        >
          <GridItem colSpan={{ base: 12, xl: 6 }}>
            <video
              ref={videoRef2}
              loop
              style={{
                height: width < 992 ? "306px" : "535px",
                width: "100%",
              }}
              //@ts-ignore
              autoPlay={true}
              //@ts-ignore
              playsInline="playsInline"
              //@ts-ignore
              muted="muted"
              src={GuaranteedMp4}
            ></video>
          </GridItem>
          <GridItem colSpan={{ base: 12, xl: 6 }}>
            <VStack
              padding={{ base: "24px", lg: "32px" }}
              spacing={{ base: 4, lg: 1 }}
              alignItems={{ base: "center", lg: "start" }}
              fontFamily={"Jost"}
              fontWeight={700}
            >
              <VStack w="full">
                <Text
                  background={
                    "radial-gradient(50.59% 160.64% at 12.02% -26.12%, #F95F0B 0%, #E2103D 100%)"
                  }
                  backgroundClip="text"
                  fontSize={{ base: '20px', lg: "26px" }}
                  fontWeight={700}
                  fontFamily="Jost-Bold"
                  lineHeight={"38px"}
                  alignSelf={{ base: "center", lg: "end" }}
                >
                  ○ NFT Drop
                </Text>
              </VStack>

              <Text
                fontWeight={400}
                color="#FFFFFF"
                fontFamily={"Jost"}
                fontSize={{ base: "14px", lg: "16px" }}
                lineHeight={{ base: "20px", lg: "23px" }}
                textAlign={{ base: "center", lg: "right" }}
                maxWidth={{ base: "full" }}
                letterSpacing="0.3px"
              >
                NFT Drops feature allows for the distribution of NFT to a select
                group of user as rewards for completing missions or for
                promotional purposes. Project NFT owners can set aside a
                specific number of NFTs to be used for airdrops, incentivizing
                user participation and spreading awareness of the project.
              </Text>

              <PrimaryButton
                alignSelf={"end"}
                w={{ base: "full", lg: "174px" }}
                h={{ base: '40px', lg: "58px" }}
                borderRadius="100px"
                borderColor={'transparent'}
                background="radial-gradient(50.59% 160.64% at 12.02% -26.12%, #F95F0B 0%, #E2103D 100%)"
                p={{ base: "16px 26px" }}
                onClick={() => {
                  window.open("https://mint.nftfeed.guru/", '_blank');
                }}
              >
                <Text
                  fontFamily={"Jost-Medium"}
                  fontSize={{ base: "16px", md: "18px" }}
                  lineHeight={{ base: "25px", md: "26px" }}
                  fontWeight={700}
                  letterSpacing={"-0.03em"}
                  color={"#FFFFFF"}
                >
                  Join Now
                </Text>
              </PrimaryButton>

            </VStack>
          </GridItem>
        </Grid>
      )}
    </>
  );
};
