import { memo } from 'react'
import { useWindowSize } from '~/hooks/@global'
import { HeaderDesktop } from './HeaderDesktop'
import { HeaderMobile } from './HeaderMobile'

export const Header = memo(() => {
  const { width } = useWindowSize()

  if (width <= 1100) {
    return <HeaderMobile />
  }

  return <HeaderDesktop />
})
