import { NoBscProviderError } from "@binance-chain/bsc-connector";
import { AbstractConnector } from '@web3-react/abstract-connector';
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
    NoEthereumProviderError,
    UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
import {
    UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
    WalletConnectConnector,
} from '@web3-react/walletconnect-connector'

import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";


import { configEnv, ICodeChainList } from "~/@config";


import ChainConfig from "~/@constants/ChainConfig";
import { useAlert } from "~/hooks/@global";
import { EChainLocalKey, EConnectorId } from "~/@constants/chain.enum";
import { ethers } from "ethers";
import { connectorLocalStorageKey } from '~/config/WalletConfig'
import useCustomToast from "~/hooks/useCustomToast";

const { CHAINS, DEFAULT_CHAIN_CODE } = configEnv();
const { connectorsByName, mapIdCode, } = ChainConfig;


export interface IWalletContext {
    addChain: (chainCode: ICodeChainList) => Promise<boolean>;
    account: string | undefined;
    isActive: boolean;
    isLoading: boolean;
    connector?: AbstractConnector;
    library: ethers.providers.Web3Provider,
    login: (connectorId: EConnectorId) => void;
    logout: (pathRedirect?: string) => void;
    getCurrentCode: () => ICodeChainList;
}

const WalletContext = createContext<IWalletContext>(null);

const WalletProvider = ({ children }) => {
    const toast = useCustomToast();
    const { activate, account, library, connector, active, deactivate, chainId } = useWeb3React();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        localStorage.setItem(EChainLocalKey.chainCode, mapIdCode[chainId])
    }, [chainId]);

    const addChain = async (chainCode: ICodeChainList) => {
        console.log(`=====addChain=====`);
        const provider = window.ethereum;
        const selectedChain = CHAINS[chainCode];
        const { chainId: selectedChainId } = selectedChain;
        if (!provider) {
            console.error(
                "Can't setup the BSC network on metamask because window.ethereum is undefined",
            )
            return false
        }
        try {
            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: `0x${selectedChainId.toString(16)}` }]
                });
                return true;
            } catch (err) {
                // This error code indicates that the chain has not been added to MetaMask
                if (err.code === 4902) {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                ...selectedChain,
                                chainId: `0x${selectedChainId.toString(16)}`,
                            },
                        ]
                    });
                    return true;
                }
                return false;
            }
        } catch (error) {
            console.error('Failed to setup the network in Metamask:', error)
            return false
        }
    }
    const login = useCallback((connectorId: EConnectorId) => {
        console.log(`=====LOGIN=====`);
        const connector = connectorsByName[connectorId];
        if (connector) {
            activate(connector, async (error) => {
                console.log('-------------------');
                console.log({ error });
                console.log('-------------------');
                if (error instanceof UnsupportedChainIdError) {
                    const chainCode = mapIdCode[chainId] || DEFAULT_CHAIN_CODE;
                    const hasSetup = await addChain(chainCode)
                    if (hasSetup) {
                        activate(connector)
                    }
                } else {
                    window.localStorage.removeItem(EChainLocalKey.connectorId)
                    if (
                        error instanceof NoEthereumProviderError ||
                        error instanceof NoBscProviderError
                    ) {
                        // toast.error("Provider Error", "No provider was found");
                        toast.show({
                            title: 'Error',
                            subTitle: `Provider Error`,
                            description: `No provider was found`,
                            type: 'error',
                        })
                    } else if (
                        error instanceof UserRejectedRequestErrorInjected ||
                        error instanceof UserRejectedRequestErrorWalletConnect
                    ) {
                        if (connector instanceof WalletConnectConnector) {
                            const walletConnector = connector
                            walletConnector.walletConnectProvider = null
                        }

                        // toast.error("Authorization Error", "Please authorize to access your account");
                        toast.show({
                            title: 'Error',
                            subTitle: "Authorization Error!",
                            description: `Please authorize to access your account`,
                            type: 'error',
                        })
                    } else {
                        console.log(error.name, error.message)
                        // toast.error(error.name, error.message)
                        toast.show({
                            title: 'Error',
                            subTitle: error.name,
                            description: error.message,
                            type: 'error',
                        })
                    }
                }
            })
        } else {
            console.log('Unable to find connector')
            toast.show({
                title: 'Error',
                subTitle: `Unable to find connector`,
                description: `The connector config is wrong`,
                type: 'error',
            })
            // toast.error("Unable to find connector", "The connector config is wrong")
        }
        localStorage.setItem(EChainLocalKey.accountActive, account || "");
        localStorage.setItem(EChainLocalKey.logout, "");
        if (localStorage.getItem(EChainLocalKey.firstLogin) && localStorage.getItem(EChainLocalKey.firstLogin) === "1") {
            localStorage.setItem(EChainLocalKey.firstLogin, "2");
        } else {
            localStorage.setItem(EChainLocalKey.firstLogin, "1");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const logout = useCallback((pathRedirect: string = "/") => {
        deactivate()
        localStorage.setItem(EChainLocalKey.logout, "logout");
        // This localStorage key is set by @web3-react/walletconnect-connector
        if (window.localStorage.getItem('walletconnect')) {
            // connectorsByName.walletconnect?.close()
            // connectorsByName.walletconnect?.walletConnectProvider = null
        }
        navigate(pathRedirect);
    }, [deactivate, navigate])

    // Init Loading
    // useEffect(() => {
    //     if (!localStorage.getItem(EChainLocalKey.logout)) {
    //         login(EConnectorId.Injected)
    //     }
    // }, [login])


    const getCurrentCode = useCallback(() => {
        return mapIdCode[chainId] || DEFAULT_CHAIN_CODE;
    }, [chainId])


    const values = useMemo(
        () => ({
            addChain,
            account,
            isActive: active,
            isLoading,
            connector,
            library,
            login,
            logout,
            getCurrentCode,
        }),
        [account, active, isLoading, connector, library, login, logout, getCurrentCode]
    )

    return <WalletContext.Provider value={values}>{children}</WalletContext.Provider>
}


export { WalletContext, WalletProvider }