import { Box, VStack, Text, Image, HStack, Flex, SimpleGrid } from "@chakra-ui/react";
import Partner1Img from "~/assets/home/partner1.png";
import Partner2Img from "~/assets/home/partner2.png";
import Partner3Img from "~/assets/home/partner3.png";
import Partner4Img from "~/assets/home/partner4.png";
import Partner5Img from "~/assets/home/partner5.png";
import Partner6Img from "~/assets/home/partner6.jpeg";

import InsiderBase from "~/assets/home/insider_base.jpeg"
import BaseInsiderTIG from "~/assets/home/Baseinsider_TIG.jpeg"
import Base_Name_Service from "~/assets/home/Base_Name_Service.jpeg"
import Base_Daily_News from "~/assets/home/Base_Daily_News.png"
import BaseDailyMM from "~/assets/home/BaseDailyMM.jpeg"
import CloudBase_Fin from "~/assets/home/CloudBase_Fin.png"
import BeagleSwap from "~/assets/home/beagle_swap.jpeg"
import TaskOn from "~/assets/home/taskon.png"
import BaseAllday from "~/assets/home/Base_Allday.jpeg"
import Gogem from "~/assets/home/Gogem.jpeg"
import BaseRock from "~/assets/home/Base_Rock.jpeg"

import parnerBg from "~/assets/home/bg3.png";
import { useEffect } from "react";

import Slider from "react-slick";
import { useRef, useState } from "react";
import { useWindowSize } from "~/hooks/@global";
const DATA = [
  {
    id: 1,
    img: Partner1Img,
    name: "Base Universe",
    href: "https://www.baseuniverse.space/",
  },
  {
    id: 2,
    img: Partner2Img,
    name: "Web3Pay",
    href: "https://web3payu.com/",
  },
  {
    id: 3,
    img: Partner3Img,
    name: "Giveaway.com",
    href: "https://giveaway.com/",
  },
  {
    id: 4,
    img: Partner4Img,
    name: "DOS Chain",
    href: "https://doschain.com/",
  },
  {
    id: 5,
    img: Partner5Img,
    name: "Base Avocates",
    href: "https://twitter.com/BaseAdvocates",
  },
  {
    id: 6,
    img: Partner6Img,
    name: "TokTok",
    href: "https://twitter.com/TokToksocialfi",
  },
  {
    id: 7,
    img: InsiderBase,
    name: "Base Insiders",
    href: "https://twitter.com/insider_base",
  },
  {
    id: 8,
    img: BaseInsiderTIG,
    name: "Base Insider",
    href: "https://twitter.com/Baseinsider_TIG",
  },
  {
    id: 9,
    img: Base_Name_Service,
    name: "Base Name Service",
    href: "https://www.basename.app/",
  },
  {
    id: 10,
    img: Base_Daily_News,
    name: "Base Daily News",
    href: "https://twitter.com/BuildOnBaseNews",
  },
  {
    id: 11,
    img: BaseDailyMM,
    name: "Base Daily",
    href: "https://twitter.com/BaseDailyMM",
  },
  {
    id: 12,
    img: CloudBase_Fin,
    name: "Cloud Base",
    href: "https://cloudbase.finance/?chainId=84531",
  },
  {
    id: 13,
    img: BeagleSwap,
    name: "BeagleSwap",
    href: "https://beagleswap.xyz/",
  },
  {
    id: 14,
    img: TaskOn,
    name: "TaskOn",
    href: "https://taskon.xyz/",
  },
  {
    id: 15,
    img: BaseAllday,
    name: "Base Allday",
    href: "https://twitter.com/Base_Allday_",
  },
  {
    id: 16,
    img: Gogem,
    name: "Gogems",
    href: "https://gogems.vercel.app/",
  },
  {
    id: 17,
    img: BaseRock,
    name: "BaseRock",
    href: "https://twitter.com/rockinbase",
  },
];

export const Partners = () => {
  const { width } = useWindowSize();
  const [activeIndex, setActiveIndex] = useState(0);
  const sliderRef = useRef(null);

  useEffect(() => {
    sliderRef.current?.slickGoTo(activeIndex);
  }, [activeIndex]);

  return (
    <VStack
      w="full"
      py="100px"
    >

      <Text
        color={"#FFFFFF"}
        fontFamily={"Jost-Bold"}
        fontWeight={800}
        fontSize={{ base: "32px", lg: "38px" }}
        lineHeight={{ base: "41px", lg: "44px" }}
        textAlign={{ base: "center" }}
        marginTop={0}
        pb={"24px"}
      >
        Our Partners
      </Text>

      <SimpleGrid
        w="full"
        spacing={4}
        columns={{ base: 2, md: 4, lg: 4, xl: 6 }}
      >
        {DATA.map((item) => (
          <VStack key={item.id} px={{ base: "38px", lg: "80px" }}>
            <Box>
              <Image
                src={item.img}
                w={{ base: "76px", lg: "110px" }}
                h={{ base: "76px", lg: "110px" }}
                objectFit="contain"
                onClick={() => window.open(item.href)}
                cursor="pointer"
                borderRadius={'100px'}
              />
            </Box>
            <Text
              fontSize={{ base: '14px', lg: "18px" }}
              fontFamily={"Jost-Medium"}
              fontWeight={500}
              color={"#FFFFFF"}
              textAlign="center"
              lineHeight={'26px'}
            >
              {item.name}
            </Text>
          </VStack>
        ))}
      </SimpleGrid>

    </VStack>
  )

  return (
    <Box
      w="100%"
      p={"0"}
      position={"relative"}
      paddingBottom={{ base: "45px", lg: "65px" }}
      m="0px !important"
      bgImage={parnerBg}
      backgroundPosition="right"
      backgroundRepeat="no-repeat"
      backgroundSize={"contain"}
      height={{ base: "fit-content", lg: '400px' }}
    >
      <Box h={{ base: "30px" }}></Box>
      <VStack
        spacing={{ base: 2, md: 4 }}
        alignItems={{ base: "center" }}
        paddingTop={{ base: "0px", lg: "0px" }}
        px={{ base: "16px", lg: "30px", xl: "120px" }}
      >
        <Text
          color={"#FFFFFF"}
          fontFamily={"Jost-Bold"}
          fontWeight={800}
          fontSize={{ base: "32px", lg: "38px" }}
          lineHeight={{ base: "41px", lg: "44px" }}
          textAlign={{ base: "center" }}
          marginTop={0}
          pb={"24px"}
        >
          Our Partner
        </Text>

        <Flex
          flex={1}
          w="100%"
          flexWrap="nowrap"
          position={"relative"}
          zIndex={1}
          maxWidth={{ base: "100%" }}
        >
          <Box w="100%" overflow="hidden">
            <Slider
              ref={sliderRef}
              speed={1000}
              dots={false}
              autoplay={true}
              //infinite={false}
              slidesToShow={1}
              slidesToScroll={1}
              centerMode={true}
              onSwipe={(e) => {
                console.log(e);
              }}
              beforeChange={(current, next) => setActiveIndex(next)}
              afterChange={(current) => setActiveIndex(current)}
              nextArrow={null}
              prevArrow={null}
              variableWidth={true}
            //variableWidth={width <= 1100 ? false : true}
            >
              {DATA.map((item) => (
                <VStack key={item.id} px={{ base: "38px", lg: "80px" }}>
                  <Box>
                    <Image
                      src={item.img}
                      w={{ base: "76px", lg: "110px" }}
                      h={{ base: "76px", lg: "110px" }}
                      objectFit="contain"
                      onClick={() => window.open(item.href)}
                      cursor="pointer"
                      borderRadius={'100px'}
                    />
                  </Box>
                  <Text
                    fontSize={{ base: '14px', lg: "18px" }}
                    fontFamily={"Jost-Medium"}
                    fontWeight={500}
                    color={"#FFFFFF"}
                    textAlign="center"
                    lineHeight={'26px'}
                  >
                    {item.name}
                  </Text>
                </VStack>
              ))}
            </Slider>
          </Box>
        </Flex>

        <HStack w="full" bottom={"0px"} spacing={2} justifyContent={"center"}>
          {DATA.map((_, index) => {
            return (
              <Box
                key={index}
                cursor={"pointer"}
                onClick={() => {
                  setActiveIndex(index);
                }}
                w={index === activeIndex ? "8px" : "5px"}
                h={index === activeIndex ? "8px" : "5px"}
                borderRadius="100px"
                bg={index === activeIndex ? `#F95F0B` : `#424242`}
                transition="all 250ms ease-in-out"
              />
            );
          })}
        </HStack>

      </VStack>
    </Box>
  );
};
