export const MainLinks = [
    {
        title: 'Features',
        key: "features",
        href: '',
    },
    {
        title: 'Docs',
        key: "docs",
        href: 'https://nftfeed.gitbook.io/nftfeed-ver-1.3/nftfi/staking-and-farming',
    },
    {
        title: 'Contact Us',
        key: "contact-us",
        href: '',
    },
    {
        title: 'Github',
        key: "ithub",
        href: 'https://github.com/sharkie-project/token',
    },
]
