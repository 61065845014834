import {
  Box,
  Image,
  VStack,
  Text,
  Flex,
  Center,
  Divider,
  HStack,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useWindowSize } from "~/hooks/@global";
import Slider from "react-slick";
import ChevronLeftIcon from "~/assets/svgs/arrow-left-icon.svg";
import ChevronRightIcon from "~/assets/svgs/arrow-right-icon.svg";
import BuiltOn1Img from "~/assets/home/built-on1.png";
import BuiltOn2Img from "~/assets/home/built-on2.png";
import BuiltOn3Img from "~/assets/home/built-on3.png";
import { EHomeListTag } from "~/dto/nft-collection.dto";
import { useListCollection } from "~/hooks/useListCollection";
import LazyLoad from 'react-lazy-load';

const DATA = [
  {
    id: 2,
    img: "https://qa.cdn.nftfeed.guru/files/ecommerce/740606716e264a3790f165c3d3dc08daFearless%20dog.png",
    name: "Fin & Fur Unity NFT",
    price: "Free to Mint",
    description: "",
    href: "https://mint.nftfeed.guru/nft-collection/0x4549b6864b3902b8ebce89b964c59d51876a91d8",
  },
  {
    id: 3,
    img: "https://qa.cdn.nftfeed.guru/files/ecommerce/7de83f0239414760b08c630e5cf12b14Thie%CC%82%CC%81t%20ke%CC%82%CC%81%20chu%CC%9Ba%20co%CC%81%20te%CC%82n%20(58).png",
    name: "Basebie Shark Doo Doo",
    price: "Free to Mint",
    description: "",
    href: "https://mint.nftfeed.guru/nft-collection/0x7f1fc5d87f51dc178f9e883b02cada85056f47bb",
  },
  {
    id: 1,
    img: "https://qa.cdn.nftfeed.guru/files/ecommerce/bb89d1a0a0f944f7b2f454b36e96c213Thie%CC%82%CC%81t%20ke%CC%82%CC%81%20chu%CC%9Ba%20co%CC%81%20te%CC%82n%20(56).png",
    name: "Base Surfer",
    price: "Free to Mint",
    description: "",
    href: "https://mint.nftfeed.guru/nft-collection/0x98c74ca62df61d3de266237fa02df058d909d9eb",
  },
];

const SliderList = () => {
  const { width } = useWindowSize();
  const [activeIndex, setActiveIndex] = useState(0);
  const sliderRef = useRef(null);
  const videoRef = useRef(null);
  const { data: listFreeMint, isLoading: isLoadingFree } = useListCollection({
    pageSize: 12,
    listTag: EHomeListTag.FreeMint,
  });


  return (
    <>
      <Flex
        flex={1}
        w="100%"
        flexWrap="nowrap"
        position={"relative"}
        zIndex={1}
        maxWidth={{ base: "100%" }}
      >
        <Center
          position={"absolute"}
          zIndex={1}
          top="40%"
          left="2%"
          onClick={(e) => {
            e.stopPropagation();
            sliderRef.current?.slickPrev();
          }}
        >
          <Image
            src={ChevronLeftIcon}
            p="5px"
            color="#4F4F4F"
            cursor="pointer"
            h={{ base: "28px", lg: "32px" }}
            w={{ base: "28px", lg: "32px" }}
          />
        </Center>

        <Box w="100%" overflow="hidden">
          <Slider
            ref={sliderRef}
            autoplay={true}
            speed={1000}
            dots={false}
            infinite={true}
            slidesToShow={1}
            slidesToScroll={1}
            centerMode={true}
            onSwipe={(e) => {
              console.log(e);
            }}
            beforeChange={(current, next) => setActiveIndex(next)}
            afterChange={(current) => setActiveIndex(current)}
            nextArrow={null}
            prevArrow={null}
            variableWidth={width <= 1100 ? false : true}
          >
            {listFreeMint.map((item, idx) => {
              return (
                <Box p="15px">
                  <Box
                    key={idx}
                    position={"relative"}
                    borderRadius="32px"
                    // width={"360px"}
                    marginY={"10px"}
                    padding={activeIndex === idx ? "10px" : "0px 10px"}
                    boxShadow={
                      activeIndex === idx
                        ? "0px 0px 51px rgba(255, 60, 60, 0.32)"
                        : "none"
                    }
                    bg={"rgba(0, 0, 0, 0.12)"}
                  >
                    <VStack
                      borderRadius="32px"
                      alignItems={"center"}
                      spacing={2}
                      p={{ base: '5px', lg: "30px" }}
                      w="full"
                    >

                      <VStack position={"relative"}>
                        {item.image.includes(".mp4") ?
                          <Box
                            w="100%"
                            height={{ base: "195px", md: "312px" }}
                            width={{ base: "auto", md: "312px" }}
                          >
                            <video
                              loop
                              ref={videoRef}
                              style={{
                                borderRadius: "24px",
                                width: "100%",
                                height: "inherit",
                                objectFit: "cover"
                              }}
                              //@ts-ignore
                              autoPlay={true}
                              //@ts-ignore
                              playsinline="playsinline"
                              //@ts-ignore
                              muted="muted"
                              src={item.image}
                            >
                            </video>
                          </Box>
                          :
                          <Image
                            src={item.image}
                            height={{ base: "195px", md: "312px" }}
                            width={{ base: "auto", md: "312px" }}
                            objectFit={{ base: "contain", md: "cover" }}
                            borderRadius="24px"
                          />
                        }
                      </VStack>

                      <VStack
                        w="full"
                        pt="20px"
                      >

                        <Text
                          fontFamily={"Jost-Medium"}
                          fontWeight={700}
                          fontSize={{ base: "13px", lg: "16px" }}
                          lineHeight={{ base: "normal", }}
                          color="#fff"
                          textAlign={{ base: "start", md: "start" }}
                          alignSelf="start"
                          textTransform={"capitalize"}
                          noOfLines={1}
                          cursor={{ lg: "pointer" }}
                          onClick={() => {
                            window.open(`https://mint.nftfeed.guru/nft-collection/${item.chainId}/${item.address}`)
                          }}
                        >
                          {item.name}
                        </Text>

                        <HStack w={"full"} justifyContent="space-between">
                          <Text
                            fontFamily={"Jost"}
                            fontWeight={400}
                            fontSize={{ base: "14px", lg: "16px" }}
                            lineHeight={{ base: "31px", lg: "24px" }}
                            color="#c8c8c8"
                            textAlign={{ base: "center", md: "center" }}
                          >
                            Price:
                          </Text>
                          <Text
                            pl="20px"
                            fontFamily={"Jost"}
                            fontWeight={400}
                            fontSize={{ base: "14px", lg: "16px" }}
                            lineHeight={{ base: "31px", lg: "24px" }}
                            color="#97FFDF"
                            pr={"20px"}
                            textAlign={"end"}
                          >
                            {/* {item.price} */}
                            Free Mint
                          </Text>
                        </HStack>

                        <Divider />

                        <Text
                          background="radial-gradient(50.59% 160.64% at 12.02% -26.12%, #F95F0B 0%, #E2103D 100%)"
                          backgroundClip={"text"}
                          fontFamily="Jost"
                          fontSize={{ base: '16px', lg: "18px" }}
                          lineHeight="26px"
                          fontWeight={400}
                          alignSelf={"start"}
                          cursor={{ lg: "pointer" }}
                          onClick={() => {
                            window.open(`https://mint.nftfeed.guru/nft-collection/${item.chainId}/${item.address}`)
                          }}
                        >
                          Read more
                        </Text>
                      </VStack>

                    </VStack>
                  </Box>
                </Box>
              );
            })}
          </Slider>
        </Box>

        <Center
          position={"absolute"}
          zIndex={1}
          top="40%"
          right="2%"
          onClick={(e) => {
            e.stopPropagation();
            sliderRef.current?.slickNext();
          }}
          borderRadius={"100px"}
        >
          <Image
            src={ChevronRightIcon}
            p="5px"
            color="#4F4F4F"
            cursor="pointer"
            h={{ base: "28px", lg: "32px" }}
            w={{ base: "28px", lg: "32px" }}
          />
        </Center>
      </Flex>
      <HStack w="full" bottom={"0px"} spacing={2} justifyContent={"center"}>
        {listFreeMint.map((_, index) => {
          return (
            <Box
              key={index}
              cursor={"pointer"}
              onClick={() => {
                setActiveIndex(index);
              }}
              w={index === activeIndex ? "8px" : "5px"}
              h={index === activeIndex ? "8px" : "5px"}
              borderRadius="100px"
              bg={index === activeIndex ? `#F95F0B` : `#424242`}
              transition="all 250ms ease-in-out"
            />
          );
        })}
      </HStack>
    </>
  );
};

export const WhoAreWe = () => {
  const { width } = useWindowSize();

  return (
    <Box
      w="100%"
      // background={"#0E0D22"}
      position={"relative"}
      paddingBottom={{ base: "45px", lg: "15px" }}
    >
      <VStack
        spacing={{ base: 2, md: 4 }}
        alignItems={{ base: "center" }}
        paddingTop={{ base: "35px", lg: "51px" }}
        px={{ base: "16px", lg: "20px", xl: "20px" }}
      >
        <Text
          color={"#FFFFFF"}
          fontFamily={"Jost-Bold"}
          fontWeight={800}
          fontSize={{ base: "32px", lg: "38px" }}
          lineHeight={{ base: "41px", lg: "44px" }}
          textAlign={{ base: "center" }}
          marginTop={0}
          pb={"24px"}
        >
          Built On NFTFeed
        </Text>
        <SliderList />
      </VStack>
    </Box>
  );
};
