import { Button, ButtonProps, } from '@chakra-ui/react'

const PrimaryButton = ({ children, disabled, ...props }: ButtonProps) => {
    return (
        <Button
            p={{
                base: "4px 10px",
                lg: "12px 20px"
            }}
            color="#fff"
            fontWeight={600}
            fontSize={{
                base: "14px",
                lg: "16px"
            }}
            lineHeight={{
                base: "18px",
                lg: "21px"
            }}
            borderRadius="100px"
            //boxShadow='0px 0px 10px #4194FF'
            background="#4194FF"
            border="4px solid #4194FF"
            disabled={disabled}
            _disabled={{
                backgroundColor: '#A2A2A2',
                background: "#E2E2E2",
                color: '#A1A1A1',
            }
            }
            _focus={{
                backgroundColor: '#4194FF',
            }}
            _hover={{
                boxShadow: '0px 0px 10px 0px #4194FF',
            }}
            loadingText={typeof children === 'string' ? children : ''}
            {...props}
        >
            {children}
        </Button >
    )
}

export default PrimaryButton
