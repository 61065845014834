import { useState } from "react";
import {
  Box,
  Image,
  VStack,
  Text,
  useColorMode,
  GridItem,
  Grid,
  Divider,
  HStack,
} from "@chakra-ui/react";
import BoxImg1 from "~/assets/home/box1.png";
import BoxImg2 from "~/assets/home/box2.png";
import BoxImg3 from "~/assets/home/box3.png";
import BoxImg4 from "~/assets/home/box4.png";
import BoxImg5 from "~/assets/home/box5.png";
import liquidityBg from "~/assets/home/bg2.png";

const DATA = [
  {
    title: "Liquidity Adding",
    subTitle:
      "NFT liquidity give the right to users of adding NFTs to a liquidity pool and receive LP tokens in return. These LP tokens can then be used to trade, stake or provide liquidity for other assets on DEX platforms. Liquidity Providers will receive revenue sharing from the transaction fees generated by the LP pair they created",
    image: BoxImg1,
  },
  {
    title: "Vault Creation",
    subTitle:
      "A place where liquidity is added by the Vault Owner. Vault creation provides a solution for Vault Owner to add LP for any type of NFT they want  through selling it.",
    image: BoxImg2,
  },
  {
    title: "Minting",
    subTitle:
      "Minting is the process of adding an NFT to an NFT Vault and creating a amount of xToken based on a ratio pre-defined by the Vault Owner and offering users to sell a portion of their NFT's value. These tokens can be used to trade, stake or provide liquidity for other assets on DEX platforms.",
    image: BoxImg3,
  },
  {
    title: "Redemption",
    subTitle:
      "Redemption is a feature that enables users to claim ownership of NFT (random NFT or target NFT)  in a Vault by utilizing xToken.  However, if there are no more NFTs left in the Vault, users can only mint random NFTs and cannot use the target minting NFT feature.",
    image: BoxImg4,
  },
  {
    title: "NFT Affiliate",
    subTitle:
      "NFT Affiliate is a tool that guarantees all NFT proceeds are added to the liquidity pool for secure NFT purchases.",
    image: BoxImg5,
  },
];

export const NFTLiquidity = () => {
  const [selectItem, setSelectItem] = useState(0);

  return (
    <Box w="100%" position={"relative"} m="0px !important">
      <VStack
        w="full"
        alignItems={"center"}
        spacing={4}
        py="50px"
        // background={"#0E0D22"}
      >
        <Text
          background={
            "radial-gradient(50.59% 160.64% at 12.02% -26.12%, #F95F0B 0%, #E2103D 100%)"
          }
          backgroundClip="text"
          fontFamily={"Jost-Medium"}
          fontWeight={500}
          fontSize={{ base: "32px", lg: "42px" }}
          lineHeight={{ base: "41px", lg: "61px" }}
          textAlign={{ base: "center" }}
          marginTop={0}
          pb={"24px"}
          textShadow={"0px 0px 46px #FB0439"}
          id="features"
        >
          NFT Liquidity
        </Text>
        <Text
          color={"#FFFFFF"}
          fontFamily={"Jost-Bold"}
          fontWeight={800}
          fontSize={{ base: "30px", lg: "38px" }}
          lineHeight={{ base: "33px", lg: "54px" }}
          textAlign={{ base: "center" }}
          marginTop={0}
          px={{base: '20px', lg: '0px'}}
          pb={"24px"}
        >
          Innovation Liquidity Solutions For All Kind Of NFT
        </Text>
        <Text
          color={"#FFFFFF"}
          fontFamily={"Jost"}
          fontWeight={"400"}
          textAlign={"center"}
          px={{base: '20px', lg: '0px'}}
          pb="30px"
          lineHeight={{base: '20px', lg: '28px'}}
          fontSize={{base: '14px', lg:"16px"}}
        >
          NFT liquidity often pertains to the ability to add NFTs to a liquidity
          pool and receive LP (liquidity provider) tokens in return.These LP
          tokens can then be used to trade, stake or provide liquidity for other
          assets on DEX platforms.
        </Text>

        <Grid
          alignItems="start"
          position={"relative"}
          m="0px !important"
          // bgImage={liquidityBg}
          // backgroundPosition="left"
          // backgroundRepeat="no-repeat"
          // backgroundSize={"cover"}
          // height={{ base: "fit-content" }}
          w="full"
          templateColumns="repeat(7, 1fr)"
          gap={8}
        >
          <GridItem colSpan={{ base: 7, xl: 4 }}>
            <Image src={DATA[selectItem].image} />
          </GridItem>

          <GridItem colSpan={{ base: 7, xl: 3 }} pr={"20px"}>
            <VStack
              spacing={0}
              border="1px solid #F95F0B"
              borderRadius={"24px"}
              marginLeft='20px'
            >
              {DATA.map((item, idx) => {
                return (
                  <VStack
                    key={idx}
                    w="full"
                    cursor="pointer"
                    alignItems="start"
                    spacing={1}
                    bg="rgba(29,29,35,.3)"
                    boxShadow="0 32px 64px rgba(0,0,0,.12)"
                    p={{ base: "28px", lg: "38px" }}
                    borderBottom="1px solid rgba(105,106,111,.5)"
                    onClick={() => {
                      setSelectItem(idx);
                    }}
                    onMouseOver={() => {
                      setSelectItem(idx);
                    }}
                    onMouseLeave={() => {
                      setSelectItem(0);
                    }}
                  >
                    <HStack>
                      {selectItem === idx ? (
                        <Text
                          background={
                            "radial-gradient(50.59% 160.64% at 12.02% -26.12%, #F95F0B 0%, #E2103D 100%)"
                          }
                          backgroundClip="text"
                          fontSize={{base: '20px', lg: "26px"}}
                          fontWeight={700}
                          fontFamily="Jost-Bold"
                          lineHeight={{base: '28px', lg:"38px"}}
                          alignSelf="end"
                        >
                          ○ {item.title}
                        </Text>
                      ) : (
                        <Text
                          fontSize={{base: '20px', lg: "26px"}}
                          fontWeight={600}
                          fontFamily="Jost-Bold"
                          lineHeight={{base: '28px', lg:"38px"}}
                          alignSelf="end"
                          color={"#FFFFFF"}
                        >
                          {item.title}
                        </Text>
                      )}
                    </HStack>

                    {selectItem === idx && (
                      <Text
                        fontSize={{base: '14px', lg: "16px"}}
                        lineHeight="24px"
                        fontFamily={'Jost'}
                        fontWeight={400}
                        color="#fff"
                        opacity={"0.8"}
                      >
                        {item.subTitle}
                      </Text>
                    )}
                  </VStack>
                );
              })}
            </VStack>
          </GridItem>
        </Grid>
      </VStack>
    </Box>
  );
};
