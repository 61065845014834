import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import { ReactComponent as NotFoundIcon } from '~/assets/svgs/NotFoundIcon.svg'
import MainLayout from '~/components/MainLayout'

const NotFoundView = () => {
  return (
    <MainLayout>
      <Flex
        flex={1}
        justifyContent="center"
        alignItems="center"
        h="100%"
        flexDir="column"
      >
        <Box mt="150px" />
        <Box h="20vh" />
        <Icon
          as={NotFoundIcon}
          w={{ base: '415px', md: '215px' }}
          h={{ base: '66px', md: '209px' }}
        />
        <Text
          mt="16px"
          color="272727"
          fontSize="24px"
          fontWeight="700"
          lineHeight="31px"
          letterSpacing="1px"
          textTransform={"capitalize"}
        >
          Page Not Found
        </Text>
        <Box h="20vh" />
      </Flex>
    </MainLayout>
  )
}

export default NotFoundView
