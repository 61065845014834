import { extendTheme, theme } from '@chakra-ui/react'

const breakpoints = {
  base: 0,
  '320': '20em',
  '375': '23.4375em',
  sm: '30em',
  md: '48em',
  lg: '62em',
  '2lg': '64em',
  '3lg': '68.75em',
  xl: '80em',
  xxl: '89em',
  '2xl': '96em',
  '3xl': '120em',
}

const customTheme = extendTheme({
  components: {
    Button: {
      baseStyle: {
        textTransform: 'capitalize',
      },
    },
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  colors: {
    light: {
      primary: '#4194FF',
      bgHeader: '#00081C',
      footer: '#A5A5A5',
    },
    dark: {
      primary: 'red',
      bgHeader: 'red',
    },
  },
  breakpoints,
})

export default customTheme
