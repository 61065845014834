import * as envs from './envs';



export interface IChainConfig {
  chainId: number;
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  rpcUrls: string[];
  blockExplorerUrls: string[];
}

export interface IChainList {
  BSC?: IChainConfig;
  ETHEREUM?: IChainConfig;
  AURORA?: IChainConfig;
}

export type ICodeChainList = keyof IChainList;

export interface IEnvConfig {
  name?: string
  DOMAIN_URL: string
  CONNECTORS: {
    ROOT: {
      baseUrl: string
    },
    FREE_MINT: {
      baseUrl: string
    },
  }
  DEFAULT_CHAIN_CODE: ICodeChainList;
  CHAINS: IChainList;
  ADDRESS_CONFIG: {
    WBNB: string
    BUSD: string
    USDT: string
    SOL: string
    BICYCLE: string
    PYRAMID: string
    PANCAKE_FACTORY: string
    PANCAKE_ROUTER: string
    FAM_TOKEN: string
    COLLECTION_ADDRESS: string
    MEMBERSHIP_RANK_V1: string
    MEMBERSHIP_RANK: string
    FIXED_APR_STAKING_POOL: string
    VAULT_07: string
    VAULT_30: string
    VAULT_90: string
    VAULT_180: string
    // POOL_STAKE_NFT: string;
    // POOL_IDO: string;
    NFT_V1_ADDRESS: string;
    NFT_V2_ADDRESS: string;
    POOL_SWAP_NFT_ADDRESS: string;
    POOL_STAKE_NFT_ADDRESS: string;
  },
  PROJECTS: {
  },
  MORALIS_API_CHAIN: string,
  TRASH_WALLET_ADDRESS: string,
  BNB_TOKEN_SYMBOL: string,
  ERC1155_TYPE: string,
  TOKEN_UTILS_POOL_ADDRESS: string,
  GOOGLE_CLIENT_ID: string,
  GOOGLE_CLIENT_SECRET: string,
}
let envConfig: IEnvConfig
export function configEnv(): IEnvConfig {
  if (envConfig) {
    return envConfig
  }
  const envName = process.env.REACT_APP_ENV || 'qa'
  const currentConfig = (envs as any)[envName]
  return {
    ...currentConfig,
    name: envName,
  }
}
