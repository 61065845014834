import PolygonImg from "~/assets/home/polygon.png";
import BaseChainImg from "~/assets/home/basechain.png";
import BinanceImg from "~/assets/home/binance.png";
import AvalancheImg from "~/assets/home/avalanche.png";
import { useWindowSize } from '~/hooks/@global'
import { Flex, Center, Box, VStack, useColorMode, Image, Text, Grid, GridItem, HStack } from '@chakra-ui/react'
import { useRef, useState } from 'react'
import Slider from 'react-slick'


const chainInfos = [
    {
        name: "Base Chain",
        img: BaseChainImg,
    },
    {
        name: "Zeta",
        img: "https://s2.coinmarketcap.com/static/img/coins/64x64/21259.png"
    },
    {
        name: "Arbitrum",
        img: "https://dd.dexscreener.com/ds-data/chains/arbitrum.png"
    },
    {
        name: "Optimism",
        img: "https://dd.dexscreener.com/ds-data/chains/optimism.png"
    },
    {
        name: "Scroll",
        img: "https://dd.dexscreener.com/ds-data/chains/scroll.png"
    }
    // {
    //     name: "Polygon",
    //     img: PolygonImg,
    // },
    // {
    //     name: "Binance",
    //     img: BinanceImg,
    // },
    // {
    //     name: "Avalanche",
    //     img: AvalancheImg,
    // },
]

export const MultiChain = () => {
    const { width } = useWindowSize();
    const sliderRef = useRef(null)
    const [activeIndex, setActiveIndex] = useState(0)

    return (

        <Grid
            w="full"
            templateColumns='repeat(7, 1fr)'
            gap={4}
            position="relative"
            background="linear-gradient(270deg, rgba(0, 0, 0, 0.85) 22.4%, rgba(0, 0, 0, 0.733401) 35.94%, rgba(0, 0, 0, 0.619792) 61.98%, rgba(0, 0, 0, 0) 92.19%), radial-gradient(50.59% 160.64% at 12.02% -26.12%, #F95F0B 0%, #E2103D 100%)"
            px={{ base: "10px", xl: "64px" }}
            py="20px"
        >

            <GridItem
                colSpan={{ base: 2, xl: 2 }}
            >
                <Text
                    textShadow={"0px 0px 20px #FFFFFF"}
                    fontSize={{ base: '16px', md: '28px', lg: "34px" }}
                    fontWeight={700}
                    color={'#FFFFFF'}
                    fontFamily={'Jost-Bold'}
                    h={"46px"}
                >
                    Multi-chain support |
                </Text>
            </GridItem>

            <GridItem
                colSpan={{ base: 5, xl: 5 }}
                w="full"
            >
                <Flex
                    flex={1}
                    w="100%"
                    flexWrap="nowrap"
                    position={"relative"}
                    zIndex={1}
                    maxWidth={{ base: "full", lg: "940px", xl: "1280px" }}
                >

                    <Box
                        w="100%"
                        overflow="hidden"
                    >
                        <Slider
                            ref={sliderRef}
                            speed={1000}
                            dots={false}
                            autoplay={true}
                            infinite={true}
                            slidesToShow={width <= 992 ? 1 : 3}
                            slidesToScroll={1}
                            onSwipe={(e) => {
                                console.log(e)
                            }}
                            beforeChange={(current, next) => setActiveIndex(next)}
                            afterChange={(current,) => setActiveIndex(current)}
                            nextArrow={null}
                            prevArrow={null}
                            variableWidth={true}
                        >
                            {chainInfos.map((item, idx) => {
                                return (
                                    <Box key={idx} pr="40px">
                                        <HStack>

                                            <Image
                                                src={item.img}
                                                h={{ base: '34px', lg: "60px" }}
                                                objectFit={"cover"}
                                                px="20px"
                                            />
                                            <Text
                                                fontWeight="800"
                                                fontSize={{ base: '14px', lg: "24px" }}
                                                lineHeight={{ base: '20px', lg: "48px" }}
                                                whiteSpace="nowrap"
                                                color="#fff"
                                            >
                                                {item.name}
                                            </Text>
                                        </HStack>

                                    </Box>
                                )
                            })}
                        </Slider>
                    </Box>

                </Flex>

            </GridItem>

        </Grid >

    )
};
