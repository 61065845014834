import { IEnvConfig } from '..'

const config: IEnvConfig = {
  name: 'QA',
  DOMAIN_URL: 'https://qa.web3insider.info',
  CONNECTORS: {
    ROOT: {
      baseUrl: 'https://qa-api-nft.famcentral.finance',
    },
    FREE_MINT: {
      baseUrl: "https://api.nftfeed.guru"
    },
  },
  DEFAULT_CHAIN_CODE: "BSC",
  CHAINS: {
    BSC: {
      chainId: 97,
      chainName: "bsc_testnet",
      nativeCurrency: {
        name: 'BNB',
        symbol: 'bnb',
        decimals: 18
      },
      rpcUrls: [
        "https://data-seed-prebsc-2-s1.binance.org:8545",
        "https://data-seed-prebsc-2-s1.binance.org:8545",
        "https://data-seed-prebsc-2-s1.binance.org:8545"
      ],
      blockExplorerUrls: ["https://testnet.bscscan.com"],
    },
  },
  ADDRESS_CONFIG: {
    WBNB: '0x0dE8FCAE8421fc79B29adE9ffF97854a424Cad09'.trim(),
    BUSD: '0x78867BbEeF44f2326bF8DDd1941a4439382EF2A7'.trim(),
    SOL: '0x78867BbEeF44f2326bF8DDd1941a4439382EF2A7'.trim(),
    USDT: "0x7ef95a0FEE0Dd31b22626fA2e10Ee6A223F8a684".trim(),
    BICYCLE: "0x0dB7062d618e7Ed7ea79e4A2B273Cc375fc27dCe".trim(),
    PYRAMID: "0x0dB7062d618e7Ed7ea79e4A2B273Cc375fc27dCe".trim(),
    PANCAKE_FACTORY: '0x5Fe5cC0122403f06abE2A75DBba1860Edb762985'.trim(),
    PANCAKE_ROUTER: '0xCc7aDc94F3D80127849D2b41b6439b7CF1eB4Ae0'.trim(),
    FAM_TOKEN: '0x302e8CD8bb32628364F918a3775F0E599BA5770C',
    COLLECTION_ADDRESS: '0x5fD95ae46C67668807d42DEa0fe70503894D23c2',
    FIXED_APR_STAKING_POOL: '0x66C3c03B5Fd02B5cc403154a99B33C26a31e0134',
    // MEMBERSHIP_RANK: '0xf06c92d9f9D3B73b46712290b5396619C72a64Ea',
    MEMBERSHIP_RANK_V1: "0xf06c92d9f9D3B73b46712290b5396619C72a64Ea",
    MEMBERSHIP_RANK: '0x996fF6BCA40aD12D6c82E20dE13BD7B1c39B4dc9',
    VAULT_07: "0xe17D031C646e843DA5D182861Ff8394D5115c8C3",
    VAULT_30: "0xeDe96677092da7471eAd755605C7d9e50115639a",
    VAULT_90: "0x5F63620AEE897f1476eCC27047e3A07177Eb3f6B",
    VAULT_180: "0x44f19DBA1217fD25A797A94309bfCB5f295eAB48",
    NFT_V1_ADDRESS: "0xD0b29a997aa6860381181560500457814E5798cd",
    NFT_V2_ADDRESS: "0xb97CD6709178503779361f587cdaA19a1142E752",
    POOL_SWAP_NFT_ADDRESS: "0xa17a5b0d4eF36Fa9E93A2C1f26EdacDD0A0b4579",
    POOL_STAKE_NFT_ADDRESS: "0x9bdCa3C0c3F26AacF6FDB531cA0871FF3aAd3845"
  },
  PROJECTS: {
  },
  MORALIS_API_CHAIN: "bsc testnet",
  TRASH_WALLET_ADDRESS: "0xa003B365E113F7c35a98d451689969aF1E70E559",
  BNB_TOKEN_SYMBOL: "BNB",
  ERC1155_TYPE: "ERC1155",
  TOKEN_UTILS_POOL_ADDRESS: "0xb0E7089d2af365f33E0053d83aE97E7a441A5470",
  GOOGLE_CLIENT_ID: "1028163660284-uo2mcidbqlrvusbec3dm5nffc6d5ill7.apps.googleusercontent.com",
  GOOGLE_CLIENT_SECRET: "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",
}

export default config
