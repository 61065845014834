import React, { useEffect } from 'react'
import { Box, Flex, useColorMode, } from '@chakra-ui/react'
import { Header } from './Header'
import Footer from './Footer'
import { useQueryParams, withDefault, StringParam } from 'use-query-params'
import { codeHelpers } from '~/@helpers/code.helpers'
import AppBg from "~/assets/home/appBg.png";


const MainLayout: React.FC = ({ children }) => {
    const { colorMode, } = useColorMode();
    const [{ keyword }] = useQueryParams({
        keyword: withDefault(StringParam, ''),
    })

    useEffect(() => {
        if (keyword) {
            codeHelpers.scrollToTop(keyword)
        }
    }, [keyword])

    return (
        <Flex overflow="hidden" position="relative">

            <Box
                background={`#0E0D22`}
                //background={`${colorMode}.bgPrimary`}
                backgroundSize="cover"
                overflowY="auto"
                position="relative"
                w="100%"
            >

                <Box id="headerScroll">
                    <Header />
                </Box>

                <Box pt={{ base: "40px", lg: "60px" }} w="full"
                    m="0px !important"
                    bgImage={AppBg}
                    backgroundPosition="inherit"
                    backgroundRepeat="no-repeat"
                    backgroundSize={"cover"}
                    height={{ base: "auto" }}
                >
                    {children}
                </Box>

                <Footer />

            </Box>

        </Flex>
    )
}

export default MainLayout
