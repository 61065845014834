import { Flex, HStack, Image, VStack, Button, Text } from "@chakra-ui/react";
import LogoWeb from "~/assets/header/logo.png";
import { useNavigate } from "react-router-dom";
import { MainLinks } from "~/@constants/HeaderRouter";
import { useQueryParams, withDefault, StringParam } from "use-query-params";
import { codeHelpers } from "~/@helpers/code.helpers";
import { NavLinkCustom } from "./NavLinkCustom";
import { Fragment } from "react";

export const HeaderDesktop = () => {
  const history = useNavigate();

  const [keyword, setKeyword] = useQueryParams({
    keyword: withDefault(StringParam, ""),
  });

  return (
    <VStack
      position="fixed"
      zIndex={99}
      w="100%"

      background="#0E0D22"
    >
      <Flex
        h="100%"
        alignItems={"center"}
        justifyContent={"space-between"}
        maxH="95px"
        w="full"
        maxW={{ base: "100%", lg: "1000px", xl: "1440px" }}
        p={{ base: "26px 20px" }}
        my={"10px"}
        borderRadius={"140px"}
        background={"rgba(41, 36, 56, 0.35)"}
      >
        <HStack>
          <Image
            src={LogoWeb}
            cursor="pointer"
            onClick={() => {
              history("/");
              codeHelpers.scrollToTop("headerScroll");
            }}
            h="64px"
            w="180px"
          />

          <Text color={"#E4E4E4"} padding={"0 20px"}>
            |
          </Text>

          <HStack spacing={10} alignSelf="center">
            {MainLinks.map((mainLink, idx) => {
              return (
                <Fragment key={idx}>
                  <NavLinkCustom mainLink={mainLink} isMobile={false} />
                </Fragment>
              );
            })}
          </HStack>
        </HStack>

        <Button
          fontFamily={"Jost"}
          width="162px"
          borderRadius={"100px"}
          height={"48px"}
          color={"#FFFFFF"}
          padding={"16px 26px"}
          boxShadow={"0px 0px 46px #FB0439"}
          onClick={() => {
            window.open("https://mint.nftfeed.guru/nft-collection-detail/free-mint", '_blank');
          }}
          background={
            "radial-gradient(50.59% 160.64% at 12.02% -26.12%, #F95F0B 0%, #E2103D 100%)"
          }
          _hover={{}}
        >
          <Text fontWeight={"700"} fontSize={"18px"} letterSpacing={"-0.03em"} fontFamily="Jost-Bold">
            FREE MINT
          </Text>
        </Button>

      </Flex>
    </VStack>
  );
};
