import { IEnvConfig } from '..'

const config: IEnvConfig = {
  name: 'PRODUCTION',
  DOMAIN_URL: 'https://web3insider.info',
  CONNECTORS: {
    ROOT: {
      baseUrl: 'https://api-nft.famcentral.finance/',
    },
    FREE_MINT: {
      baseUrl: "https://api.nftfeed.guru/"
    },
  },
  DEFAULT_CHAIN_CODE: "BSC",
  CHAINS: {
    BSC: {
      chainId: 56,
      chainName: "BSC",
      nativeCurrency: {
        name: 'BNB',
        symbol: 'bnb',
        decimals: 18
      },
      rpcUrls: [
        "https://bsc-dataseed.binance.org",
        "https://bsc-dataseed.binance.org",
        "https://bsc-dataseed.binance.org",
      ],
      blockExplorerUrls: ["https://bscscan.com"]
    },
  },
  ADDRESS_CONFIG: {
    WBNB: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c'.trim(),
    BUSD: '0xe9e7cea3dedca5984780bafc599bd69add087d56'.trim(),
    USDT: "0x55d398326f99059ff775485246999027b3197955".trim(),
    SOL: "0x570a5d26f7765ecb712c0924e4de545b89fd43df".trim(),
    BICYCLE: "0xe78ad8967e523a29d4d3a8e76422aa7ab2b6a47e".trim(),
    PYRAMID: "0x828AA11755dAF934cdbE19b0F491a81Ff272e4BC".trim(),
    PANCAKE_FACTORY: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73'.trim(), //pancakeswap
    PANCAKE_ROUTER: '0x10ED43C718714eb63d5aA57B78B54704E256024E'.trim(), // pancakeswap
    FAM_TOKEN: '0x4556a6f454f15c4cd57167a62bda65a6be325d1f',
    COLLECTION_ADDRESS: '0x92D41Ea85fe9c16E4483fDad0a471B861aF14280',
    FIXED_APR_STAKING_POOL: '0x84879F27374354c374fE0D569B12cBe614Ac00dA',
    // MEMBERSHIP_RANK: '0x56b82f79d165BFe72cd3389a4d7982cB3867656d',
    // MEMBERSHIP_RANK: '0x7676529A0fBE9e3856Df9aA778044Cb28FAF52e0',
    MEMBERSHIP_RANK_V1: '0xB4e93E70C02388994bA76966c66332ae73beCc17',
    MEMBERSHIP_RANK: '0x3F4522b7a680b9cf8e4267048EAaaaa14E28C065',
    VAULT_07: "0x2068B983DF12108C6ce123830CD33A53509e0E57",
    VAULT_30: "0x78258EfEd74F1AE2Be59f0998eB1258e25Bf7E03",
    VAULT_90: "0x8EA3669cB36F276DadCCEb028b0fCF848d29592c",
    VAULT_180: "0xD7ce726978EA75e129675f9450346F0835EE4d13",
    NFT_V1_ADDRESS: "0x92D41Ea85fe9c16E4483fDad0a471B861aF14280",
    NFT_V2_ADDRESS: "0xC7dDed3Acf32BD7BBD6CD83e22009609aC53FaAF",
    POOL_SWAP_NFT_ADDRESS: "0xA1220E80A39B875BC34Cbc7A22EbC2AFc1F056e5",
    POOL_STAKE_NFT_ADDRESS: "0xeF23f246786B722610C96FfAA66Ce6D1781F9dC4",
  },
  PROJECTS: {
  },
  MORALIS_API_CHAIN: "bsc",
  TRASH_WALLET_ADDRESS: "0xa003B365E113F7c35a98d451689969aF1E70E559",
  BNB_TOKEN_SYMBOL: "BNB",
  ERC1155_TYPE: "ERC1155",
  TOKEN_UTILS_POOL_ADDRESS: "0x7cC6DDFC8BeC56Ffb7c4C926FCB68c77331e765e",
  GOOGLE_CLIENT_ID: "1028163660284-uo2mcidbqlrvusbec3dm5nffc6d5ill7.apps.googleusercontent.com",
  GOOGLE_CLIENT_SECRET: "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",
}

export default config
