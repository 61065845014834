import PrimaryButton from "./PrimaryButton";
import { Text } from "@chakra-ui/react";

export const ButtonBuyNow = () => {
  return (
    <PrimaryButton
      w={{ base: "full", lg: "174px" }}
      h={{ base: '40px', lg: "58px" }}
      //border="2px solid #13E1A8"
      borderRadius="100px"
      borderColor={'transparent'}
      background="radial-gradient(50.59% 160.64% at 12.02% -26.12%, #F95F0B 0%, #E2103D 100%)"
      p={{ base: "16px 26px" }}
      onClick={() => {
        window.open("https://beta.dapp.nftfeed.guru", '_blank');
      }}
    >
      <Text

        fontFamily={"Jost-Medium"}
        fontSize={{ base: "16px", md: "18px" }}
        lineHeight={{ base: "25px", md: "26px" }}
        fontWeight={700}
        letterSpacing={"-0.03em"}
        color={"#FFFFFF"}
      >
        Join Beta D-APP
      </Text>
    </PrimaryButton>
  );
};
