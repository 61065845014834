import { Image, VStack, Text, HStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import LogoWeb from "~/assets/header/logo.png";

import { SocialsLayout } from "./SocialsLayout";
import TwitterIcon from "~/assets/icons/icon-twitter.svg";
import TelegramIcon from "~/assets/icons/icon-tele.svg";
import MailIcon from "~/assets/icons/icon-mail.svg";
import VectorIcon from "~/assets/icons/icon-vector.svg"
import DiscordIcon from "~/assets/icons/icon-discord.svg"

const Footer = () => {
  const navigate = useNavigate();

  return (
    <VStack
      background={`#070814`}
      w="full"
      alignItems={"center"}
      py="40px"
      borderTop={"1px solid #4a4949"}
    >
      <Text
        id="contact-us"
        fontWeight={600}
        fontSize={{ base: "14px", lg: "14px" }}
        lineHeight={{ base: "19px", lg: "22px" }}
        letterSpacing="-0.02em"
        color="#fff"
        maxWidth={{ lg: "250px", xl: "270px" }}
        textAlign={{ base: "center", lg: "start" }}
        fontFamily="Jost"
      >
        Contact us
      </Text>
      <Image
        src={LogoWeb}
        cursor="pointer"
        onClick={() => {
          navigate("/");
        }}
        h="64px"
        w="182px"
      />
      <HStack>
        <Image src={MailIcon} h={"16px"} />
        <Text
          id="contact"
          fontWeight={400}
          fontSize={{ base: "14px", lg: "14px" }}
          lineHeight={{ base: "19px", lg: "20px" }}
          letterSpacing="-0.02em"
          color="#929292"
          maxWidth={{ lg: "250px", xl: "270px" }}
          textAlign={{ base: "center", lg: "start" }}
          fontFamily="Jost"
        >
          business@nftfeed.guru
        </Text>
      </HStack>
      <SocialsLayout
        socialLinks={[
          {
            id: "1",
            title: "Telegram",
            name: "Telegram",
            icon: TelegramIcon,
            href: "https://t.me/NFTFeedOfficial",
          },
          {
            id: "2",
            title: "Twitter",
            name: "Twitter",
            icon: TwitterIcon,
            href: "https://twitter.com/NFTFeedOfficial",
          },
          {
            id: "3",
            title: "Gitbook",
            name: "Gitbook",
            icon: VectorIcon,
            href: "https://nftfeed.gitbook.io/nftfeed-ver-1.3/nftfi/staking-and-farming",
          },
          {
            id: "3",
            title: "Discord",
            name: "Discord",
            icon: DiscordIcon,
            href: "https://discord.com/invite/v65bvg5veQ",
          },
        ]}
      />
    </VStack>
  );
};

export default Footer;
