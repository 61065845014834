import { Center, VStack } from "@chakra-ui/react";

const BoxLayout: React.FC = ({ children }) => {

    return (
        <Center>
            <VStack
                w="full"
                maxW={{ base: "100%",  xl: "1440px" }}
                alignItems="start"
                // background={"#00081C"}
            >
                {children}
            </VStack>
        </Center>
    )
}

export default BoxLayout;
