import {
  Image,
  VStack,
  Text,
  useColorMode,
  SimpleGrid,
} from "@chakra-ui/react";
import BannerHomeImg from "~/assets/home/banner_bg.png";
import { ButtonBuyNow } from "~/components/ButtonBuyNow";
import { useWindowSize } from "~/hooks/@global";
import { Fragment, useRef } from "react";
//@ts-ignore
import HeaderMp4 from "~/assets/home/hearder_5.mp4";

export const BannerHome = () => {
  const { width } = useWindowSize();
  const { colorMode } = useColorMode();
  const videoRef = useRef(null);

  const renderContent = () => {
    return (
      <Fragment>
        <Text
          color="#fff"
          fontFamily={"Jost-Bold"}
          fontWeight={800}
          fontSize={{ base: "30px", md: "52px", lg: "62px" }}
          lineHeight={{ base: "34px", md: "40px", lg: "90px" }}
          textAlign={{ base: "center", lg: "start" }}
          maxWidth={{ base: "fit-content", lg: "881px" }}
        >
          Innovation NFT
        </Text>
        <Text
          pt="5px"
          color="#fff"
          fontFamily={"Jost-Bold"}
          fontWeight={800}
          fontSize={{ base: "30px", md: "52px", lg: "62px" }}
          lineHeight={{ base: "34px", md: "40px", lg: "90px" }}
          textAlign={{ base: "start", lg: "start" }}
          maxWidth={{ base: "fit-content", lg: "881px" }}
        >
          Liquidity Solution
        </Text>
        <Text
          pt="5px"
          color="#fff"
          fontFamily={"Jost-Bold"}
          fontWeight={800}
          fontSize={{ base: "30px", md: "52px", lg: "62px" }}
          lineHeight={{ base: "34px", md: "40px", lg: "90px" }}
          textAlign={{ base: "start", lg: "start" }}
          maxWidth={{ base: "fit-content", lg: "881px" }}
        >
          Fully On-chain
        </Text>

        <Text
          color={"#FFFFFF"}
          fontWeight={500}
          fontFamily={"Jost"}
          fontSize={{ base: "14px", lg: "16px" }}
          lineHeight={{ base: "20px", lg: "41px" }}
          textAlign={{ base: "center", lg: "start" }}
          p={{ base: "10px" }}
          maxWidth={{ base: "fit-content", md: "512px", lg: "774px" }}
        >
          NFTFeed, a cutting-edge NFT platform that combines Finance and Web 3.0
          to increase the intrinsic value, liquidity of NFT and Defi market.
        </Text>

        {width > 992 && (
          <VStack
            w={{ base: "fit-content", lg: "146px" }}
            alignItems={{ md: "start" }}
          >
            <ButtonBuyNow />
          </VStack>
        )}
      </Fragment>
    );
  };

  if (width < 992) {
    return (
      <VStack
        pt="10px"
        width={"full"}
        bgImg={BannerHomeImg}
        m="0px !important"
        bgImage={BannerHomeImg}
        backgroundPosition="left"
        backgroundRepeat="no-repeat"
        backgroundSize={"cover"}
        height={{ base: "fit-content" }}
      >
        <VStack
          spacing={{ base: 2, md: 4 }}
          alignItems={{ base: "start" }}
        //paddingTop={{ base: "15%" }}
        //px={{ base: "10px", md: "0px" }}
        >
          <video
            ref={videoRef}
            loop
            style={{
              height: width < 992 ? "300px" : "716px",
              width: "100%",
              objectFit: "cover",
            }}
            //@ts-ignore
            autoPlay={true}
            //@ts-ignore
            playsInline="playsInline"
            //@ts-ignore
            muted="muted"
            src={HeaderMp4}
          ></video>

          <Text
            pt="5px"
            background={"radial-gradient(50.59% 160.64% at 12.02% -26.12%, #F95F0B 0%, #E2103D 100%)"}
            backgroundClip="text"
            textShadow={"0px 0px 46px #FB0439"}
            cursor={"pointer"}
            fontFamily={"Jost-Bold"}
            fontWeight={700}
            fontSize={{ base: "30px", }}
            lineHeight={{ base: "34px", }}
            textAlign={{ base: "center", }}
            alignSelf="center"
            onClick={() => {
              window.open("https://mint.nftfeed.guru/nft-collection/0x268174ff68633901a2ec6511b33c590aac4fe263")
            }}
          >
            SHARKIE INO IS LIVE
          </Text>

        </VStack>

        {renderContent()}
        {width < 992 && (
          <VStack
            w={{ base: "fit-content", lg: "148px" }}
            alignItems={{ md: "start" }}
            my={"30px !important"}
          >
            <ButtonBuyNow />
          </VStack>
        )}
      </VStack>
    );
  }

  return (
    <SimpleGrid
      pt={{ md: "90px" }}
      pb="20px"
      w="full"
      // h="1027px"
      spacing={6}
      columns={{ base: 1, lg: 2 }}
      alignItems="center"
    // background={"#0E0D22"}
    >
      <VStack
        width={"100%"}
        alignItems="start"
        paddingLeft={{ xl: "20px", base: "20px" }}
        position={"relative"}
      // m="0px !important"
      // bgImage={BannerHomeImg}
      // backgroundPosition="left"
      // backgroundRepeat="no-repeat"
      // backgroundSize={"cover"}
      // height={{ base: "fit-content" }}
      >
        {renderContent()}
      </VStack>
      <VStack>
        <video
          ref={videoRef}
          loop
          style={{
            height: "500px",
            width: "100%",
            objectFit: "cover",
            borderRadius: "24px"
          }}
          //@ts-ignore
          autoPlay={true}
          //@ts-ignore
          playsInline="playsInline"
          //@ts-ignore
          muted="muted"
          src={HeaderMp4}
        ></video>

        <Text
          pt="5px"
          background={"radial-gradient(50.59% 160.64% at 12.02% -26.12%, #F95F0B 0%, #E2103D 100%)"}
          backgroundClip="text"
          textShadow={"0px 0px 46px #FB0439"}
          cursor={"pointer"}
          fontFamily={"Jost-Bold"}
          fontWeight={700}
          fontSize={{ base: "30px", }}
          lineHeight={{ base: "34px", }}
          textAlign={{ base: "start", lg: "start" }}
          onClick={() => {
            window.open("https://mint.nftfeed.guru/nft-collection/0x268174ff68633901a2ec6511b33c590aac4fe263")
          }}
        >
          SHARKIE INO IS LIVE
        </Text>

      </VStack>
    </SimpleGrid>
  );
};
