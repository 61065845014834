import { memo } from "react";
import BoxLayout from "~/components/BoxLayout";
import MainLayout from "~/components/MainLayout";
import {
  BannerHome,
  HowCould,
  Howtobuy,
  KeyProduct,
  Metaverse,
  MultiChain,
  NFTFeeding,
  NFTLiquidity,
  Partners,
  Problems,
  Web3Box,
  WhoAreWe,
} from "~/layouts/HomeLayout";

const HomeView = memo(() => {
  return (
    <MainLayout>
      <BoxLayout>
        <BannerHome />

        <MultiChain />
        <NFTLiquidity />
        <NFTFeeding />

        <WhoAreWe />
        <Partners />
      </BoxLayout>
    </MainLayout>
  );
});

export default HomeView;
