import { Fragment, useCallback, useMemo } from "react";
import {
  Box,
  HStack,
  useDisclosure,
  Text,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Accordion,
  Image,
  SimpleGrid,
  VStack,
  useColorMode,
  Button
} from "@chakra-ui/react";

import LogoWeb from "~/assets/header/logo.png";
import HamburgerIcon from "~/assets/svgs/hamburger-menu-white.svg";
import MenuOpenIcon from "~/assets/svgs/menu_open_white.svg";
import { MainLinks } from "~/@constants/HeaderRouter";
import { useNavigate } from "react-router-dom";
import { NavLinkCustom } from "./NavLinkCustom";

const MobileDrawer = ({ onClose, isOpen }) => {
  return (
    <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
      <DrawerContent mt="50px">
        <DrawerContent w="316px" height={"100%"}>
          <DrawerBody background="#0E0D22" backdropFilter="blur(7px)">
            <Accordion allowMultiple>
              {MainLinks.map((mainLink, idx) => {
                return (
                  <VStack key={idx} alignItems="start" spacing={0} py="10px">
                    <NavLinkCustom
                      mainLink={mainLink}
                      onClose={onClose}
                      isMobile={true}
                    />
                  </VStack>
                );
              })}
            </Accordion>
          </DrawerBody>
        </DrawerContent>
      </DrawerContent>
    </Drawer>
  );
};

export const HeaderMobile = () => {
  const history = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();

  const renderIcon = useCallback(() => {
    return (
      <HStack>
        <Image
          src={isOpen ? HamburgerIcon : MenuOpenIcon}
          fontSize="24px"
          onClick={isOpen ? onClose : onOpen}
        />
      </HStack>
    );
  }, [isOpen, onClose, onOpen]);

  return (
    <Box
      padding="6px 8px"
      h="50px"
      background={"#0E0D22"}
      zIndex={99}
      w="100%"
      position="fixed"
      boxShadow={"0px 4px 26px 0px #4194FF3D"}
    >
      <SimpleGrid columns={3} alignItems="center">
        {renderIcon()}

        <Image
          src={LogoWeb}
          justifySelf="center"
          h="30px"
          w="99px"
          objectFit={"contain"}
          onClick={() => {
            history("/");
          }}
        />

        <VStack w='full' alignItems={"end"}>
          <Button
            fontFamily={"Jost"}
            borderRadius={"100px"}
            color={"#FFFFFF"}
            boxShadow={"0px 0px 46px #FB0439"}
            onClick={() => {
              window.open("https://mint.nftfeed.guru/nft-collection-detail/free-mint", '_blank');
            }}
            background={
              "radial-gradient(50.59% 160.64% at 12.02% -26.12%, #F95F0B 0%, #E2103D 100%)"
            }
            h={{ base: "30px", }}
            w='fit-content'
            _hover={{}}
          >
            <Text fontWeight={"700"} fontSize={"12px"} letterSpacing={"-0.03em"} fontFamily="Jost-Bold">
              FREE MINT
            </Text>
          </Button>
        </VStack>


      </SimpleGrid>

      <MobileDrawer onClose={onClose} isOpen={isOpen} />
    </Box>
  );
};
